<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-dark"
    :style="openMenu ? 'transform: translate3d(260px, 0, 0);width: 260px;left: -260px;opacity:1;' : ''"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <a
            class="navbar-brand"
            href="#"
          >
            <img
              :src="require('@/assets/images/logo/logo-icrea-white-menu.svg')"
              alt="ICREA"
            >
          </a>
        </li>
        <li class="nav-item nav-toggle">
          <a
            class="nav-link modern-nav-toggle pe-0"
            data-bs-toggle="collapse"
          >
            <i
              class="d-block d-xl-none text-primary toggle-icon font-medium-4"
              data-feather="x"
            />
            <i
              class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary"
              data-feather="disc"
              data-ticon="disc"
            />
          </a>
        </li>
      </ul>
    </div>
    <div class="main-menu-content overflow-auto">
      <div
        v-if="fakeUsers && fakeUsers.length > 0 && user.roles[0] != 'super-admin'"
        class="back__menu"
      >
        <a
          title="back to the admin menu"
          @click="returnoToUser()"
        ><i data-feather="chevron-left" /> Back to admin menu</a>
      </div>
      <div class="researcher__menu__profile">
        <p>PROFILE MENU</p>
        <div class="avatar avatar-custom">
          <img
            :src="icon_path ? icon_path : require('@/assets/images/avatars/user_default.png')"
            alt="avatar"
            width="32"
            height="32"
          >
        </div>
        {{ user.name }}
      </div>
      <div style="position: relative;">
        <input
          v-model="filterName"
          class="form-control ms-1 w-90"
          placeholder="Filter menu items here..."
          type="text"
          style="background-color: #333;border: 0;outline: 0;opacity: 1;color: white !important;"
        >
        <div @click="filterName = ''">
          <i
            style="position: absolute; right: 20px; top: 50%; transform: translateY(-50%);color:white;"
            data-feather="x"
          />
        </div>
      </div>
      <ul
        id="main-menu-navigation"
        class="navigation navigation-main"
        data-menu="menu-navigation"
      >
        <template v-if="!filterName">
          <li
            v-if="convoTitle"
            class=" nav-item"
          >
            <a
              class="d-flex align-items-center"
              @click="$router.push({name: 'researcher.promotion-call.index'})"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Dashboard"
              >{{ convoTitle }}</span><span class="badge bg-danger">{{ days }} days</span>
            </a>
          </li>
          <li
            v-if="convoData.promotion && (convoData.promotion.assignPeers || convoData.promotion.evaluate)"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-4`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.promotion.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-4`">
              <ul class="menu-content">
                <li
                  v-if="convoData.promotion.assignPeers"
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations.assign-peers' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Assign peers</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
                <li
                  v-if="convoData.promotion.evaluate"
                  class=""
                >
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.promotion.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.promotion.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="convoData.academia"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseMenu-5`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoData.academia.title }}</span>
            </a>
            <b-collapse :id="`collapseMenu-5`">
              <ul class="menu-content">
                <li>
                  <a
                    class="d-flex align-items-center"
                    @click="$router.push({ name: 'evaluator.calls.evaluations' })"
                  ><i data-feather="circle" /><span
                    class="menu-item text-truncate"
                    data-i18n="Senior Call"
                  >Evaluations</span><span class="ms-50 badge bg-danger">{{ convoData.academia.days }} days</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li
            v-if="memoirProfileId || highlights"
            class="nav-item has-sub"
          >
            <a
              v-b-toggle="`collapseResearcherMenu-99`"
              class="d-flex align-items-center"
            >
              <i
                data-feather="bell"
                class="text-danger"
              /><span
                class="menu-title text-truncate"
                data-i18n="Calls"
              >{{ convoTitleMemoir }}</span><span
                v-if="daysRemaining"
                class="ms-50 badge bg-danger"
              >{{ daysRemaining }} days</span>
            </a>
            <b-collapse
              :id="`collapseResearcherMenu-99`"
              visible
            >
              <ul class="menu-content ps-2">
                <li
                  v-if="memoirProfileId"
                  class=""
                >
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir-view.view', params: { id: memoirProfileId }})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Profile</span>
                  </a>
                </li>
                <li
                  class=""
                >
                  <a
                    id="field-researcher-personal-data"
                    class="d-flex align-items-center"
                    @click="$router.push({name: 'researcher.memoir-highlight.index'})"
                  ><i class="fake-circle" /><span
                    class="menu-item text-truncate ms-1"
                    data-i18n="Senior Call"
                  >Highlights</span>
                  </a>
                </li>
              </ul>
            </b-collapse>
          </li>
        </template>
        <template v-for="menu, index in filteredMenu">
          <li
            v-if="menu.type && canSee(menu.condition)"
            :key="menu.name"
            class="navigation-header"
          >
            <span data-i18n="Configuration">{{ menu.name }}</span>
          </li>
          <li
            v-if="(!menu.condition && !menu.type && (menu.childrens && menu.childrens.length > 0 && filteredChildrens(menu).length > 0)) || !menu.type && canSee(menu.condition) || (!menu.condition && !menu.childrens && !menu.type)"
            :key="menu.name"
            class="nav-item"
            :class="{'has-sub': menu.childrens}"
          >
            <a
              :id="`field-menu-${index}`"
              v-b-toggle="`collapse-menu-${index}`"
              class="d-flex align-items-center"
              @click="openRoute(menu.route)"
            >
              <i :data-feather="menu.icon" /><span
                class="menu-title text-truncate"
              >{{ menu.name }}</span>
            </a>
            <b-collapse
              v-if="menu.childrens"
              :id="`collapse-menu-${index}`"
              :visible="visibleAllSections"
            >
              <ul class="menu-content ps-2">
                <li
                  v-for="children, childrenIndex in filteredChildrens(menu)"
                  :key="children.name"
                  :class="{'has-sub': children.childrens}"
                >
                  <a
                    v-b-toggle="`collapse-menu-children-${childrenIndex}`"
                    class="d-flex align-items-center"
                    @click="openRoute(children.route)"
                  >
                    <i class="fake-circle" />
                    <span
                      class="menu-item text-truncate"
                      data-i18n="Traces"
                    >{{ children.name }}</span>
                  </a>
                  <b-collapse
                    v-if="children.childrens"
                    :id="`collapse-menu-children-${childrenIndex}`"
                  >
                    <ul class="menu-content">
                      <li
                        v-for="childrenItem in children.childrens"
                        :key="childrenItem.name"
                      >
                        <a
                          class="d-flex align-items-center"
                          @click="openRoute(childrenItem.route)"
                        >
                          <i class="fake-circle" />
                          <span
                            class="menu-item text-truncate"
                            data-i18n="Traces"
                          >{{ childrenItem.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
              </ul>
            </b-collapse>
            <b-tooltip
              v-if="menu.tooltip"
              placement="right"
              boundary="document"
              triggers="hover"
              :target="`field-menu-${index}`"
              :title="menu.tooltip"
            />
          </li>
        </template>
      </ul>
    </div>
  </div>
<!-- END: Main Menu-->
</template>

<script>
import { BCollapse, BTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import OTP from './modals/OTP.vue'

export default {
  components: {
    BCollapse,
    BTooltip,
    OTP,
  },
  data() {
    return {
      days: null,
      convoTitle: null,
      convoTitleMemoir: null,
      memoirProfileId: null,
      daysRemaining: null,
      highlights: null,
      icon_path: '',
      convoData: [],
      filterName: '',
      menus: [
        {
          name: 'Dashboard',
          icon: 'home',
          route: 'researcher.dashboard',
        },
        {
          name: 'Alerts & notifications',
          icon: 'bell',
          route: 'alerts',
          condition: 'alerts',
        },
        {
          name: 'Profile',
          icon: 'message-square',
          childrens: [
            {
              name: 'Personal data, Biography & Research interest',
              route: 'researcher.personal-data.index',
              condition: 'personal data',
            },
            {
              name: 'Research lines',
              route: 'researcher.research-lines.index',
              condition: 'research lines',
            },
            {
              name: 'Professional background',
              route: 'researcher.professional-background.index',
              condition: 'professional backgrounds',
            },
            {
              name: 'Postdoctoral positions',
              route: 'researcher.postdoctoral-positions.index',
              condition: 'postdoctoral positions',
            },
            {
              name: 'Education',
              route: 'researcher.education.index',
              condition: 'educations',
            },
            {
              name: 'Honours & awards',
              route: 'researcher.honours-awards.index',
              condition: 'honours and awards',
            },
          ],
        },
        {
          name: 'Scientific production',
          icon: 'settings',
          childrens: [
            {
              name: 'My Scientific Production',
              icon: 'fake-circle',
              route: 'researcher.scientific-production-report.index',
              condition: 'scientific production report',
            },
            {
              name: 'Publications',
              icon: 'fake-circle',
              route: 'researcher.publications.index',
              condition: 'publications',
            },
            {
              name: 'Conferences & workshops',
              icon: 'fake-circle',
              route: 'researcher.conferences.index',
              condition: 'conferences',
            },
            {
              name: 'Courses and seminars',
              icon: 'fake-circle',
              route: 'researcher.courses.index',
              condition: 'courses',
            },
            {
              name: 'Dissemination',
              icon: 'fake-circle',
              route: 'researcher.disseminations.index',
              condition: 'disseminations',
            },
            {
              name: 'Managerial activities',
              icon: 'fake-circle',
              route: 'researcher.managerial.index',
              condition: 'managerial activities',
            },
            {
              name: 'PhD, Master Theses and TFG',
              icon: 'fake-circle',
              route: 'researcher.phds.index',
              condition: 'phds',
            },
            {
              name: 'Stays of research',
              icon: 'fake-circle',
              route: 'researcher.stays-research.index',
              condition: 'stays of research',
            },
          ],
        },
        {
          name: 'Grants & Resources',
          icon: 'mail',
          route: 'researcher.grants.index',
          condition: 'grants',
        },
        {
          name: 'Technology transfer',
          icon: 'message-square',
          childrens: [
            {
              name: 'Industrial property',
              icon: 'fake-circle',
              route: 'researcher.industrial-property.index',
              condition: 'industrial properties',
            },
            {
              name: 'Spin-off companies',
              icon: 'fake-circle',
              route: 'researcher.spin-offs.index',
              condition: 'spin-offs',
            },
          ],
        },
        {
          name: 'ICREA Memoir',
          icon: 'trello',
          childrens: [
            {
              name: 'Profiles',
              icon: 'fake-circle',
              route: 'researcher.memoir.index',
              condition: 'Memoir profiles',
            },
            {
              name: 'Highlights',
              icon: 'fake-circle',
              route: 'researcher.memoir-highlight.index',
              condition: 'Memoir highlights',
            },
          ],
        },
        {
          name: 'Other sections',
          icon: 'map',
          route: 'researcher.administration.other-sections.index',
          condition: 'other sections',
        },
        {
          name: 'Administration',
          type: 'navigation-header',
          condition: [
            'administrative data',
            'other contracts',
            'payrolls',
            'sabbaticals',
            'occupational safeties',
            'insurances',
            'collaborators',
          ],
        },
        {
          name: 'Administrative data',
          icon: 'package',
          route: 'researcher.administrative-data',
          condition: 'administrative data',
        },
        {
          name: 'Collaborators',
          icon: 'users',
          route: 'researcher.administration.collaborators.index',
          condition: 'collaborators',
        },
        {
          name: 'Other contracts',
          icon: 'file',
          route: 'researcher.administration.other-contracts.index',
          condition: 'other contracts',
        },
        {
          name: 'Payrolls',
          icon: 'dollar-sign',
          route: 'researcher.administration.payrolls.index',
          action: 'checkOtpCode',
          condition: 'payrolls',
        },
        {
          name: 'Sabbaticals',
          icon: 'calendar',
          route: 'researcher.administration.sabbaticals.index',
          condition: 'sabbaticals',
        },
        {
          name: 'OSH',
          icon: 'message-square',
          childrens: [
            {
              name: 'Occupational Safety and Health (OSH)',
              icon: 'fake-circle',
              route: 'researcher.occupational-hazards.index',
              condition: 'actAs',
            },
            {
              name: 'Insurance',
              icon: 'fake-circle',
              route: 'researcher.insurances.index',
              condition: 'insurances',
            },
          ],
        },
        {
          name: 'Functionalities',
          type: 'navigation-header',
          condition: 'knowledge base',
        },
        {
          name: 'Knowledge base',
          icon: 'book-open',
          route: 'knowledge-base.index',
          condition: 'knowledge base',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      fakeUsers: 'auth/fakeUsers',
      activeCode: 'auth/activeCode',
      openMenu: 'modals/showEvaluatorMenu',
    }),
    filteredMenu() {
      return this.menus.filter(e => {
        this.visibleAllSections = true
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 100)

        if (this.filterName) {
          return e.name.toLowerCase().includes(this.filterName.toLowerCase()) || (e.childrens && e.childrens.some(f => f.name.toLowerCase().includes(this.filterName.toLowerCase())))
        }
        this.visibleAllSections = false

        return true
      })
    },
  },
  async mounted() {
    const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/has-promotion-active`)
    this.icon_path = this.user ? this.user.avatarUrl : ''

    if (resp.data.title) {
      this.days = !resp.data.evaluate ? resp.data.days : 0
      this.convoTitle = resp.data.title
    }

    Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoirs/getActiveMemoir`).then(e => {
      if (e.data.title) {
        this.convoTitleMemoir = e.data.title
        this.memoirProfileId = e.data.profile_id ? e.data.profile_id.id : null
        this.highlights = e.data.highlights
        this.daysRemaining = e.data.days_remaining
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    })

    if (this.user.roles_all.some(e => e.evaluator === true)) {
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/has-active-calls`).then(res => {
        this.convoData = res.data
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      })
    }
  },
  methods: {
    filteredChildrens(menu) {
      return menu.childrens.filter(e => (!e.condition || this.canSee(e.condition)) && (e.name.toLowerCase().includes(this.filterName.toLowerCase()) || (e.childrens && e.childrens.some(f => f.name.toLowerCase().includes(this.filterName.toLowerCase())))))
    },
    openRoute(route) {
      if (route) {
        this.$router.push({ name: route })
      }
    },
    returnoToUser() {
      window.location.href = this.$router.currentRoute.path
    },
    canSee(section) {
      if (section == 'actAs') {
        return this.fakeUsers && this.fakeUsers.length > 0 && this.user.roles[0] != 'super-admin'
      }

      if (Array.isArray(section)) {
        let access = false

        section.forEach(e => {
          if (this.user.permissions.includes(`view ${e}`)) {
            access = true
          }
        })

        return access
      }

      return this.user.roles.includes('super-admin') || this.user.permissions.includes(`view ${section}`)
    },
    async checkOtpCode(routeName) {
      await this.$store.dispatch('auth/fetchActiveCode', this.user.id)
      if (this.activeCode !== 'OK') {
        await this.$store.dispatch('modals/saveOtpData', { route: routeName, type: 'route' })
        this.$store.dispatch('modals/showOtpModal', true)
      } else {
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>
